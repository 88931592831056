import firebase from 'firebase'
/**
 * Import this file to initialize firebase before using any other firebase module.
 */

const firebaseConfig = {
    apiKey: "AIzaSyBXrPw5ZclIHrZ5GtnMXBBhVuMgVKxgCY0",
    authDomain: "btegb-45a44.firebaseapp.com",
    databaseURL: "https://btegb-45a44.firebaseio.com",
    projectId: "btegb-45a44",
    storageBucket: "btegb-45a44.appspot.com",
    messagingSenderId: "91020574583",
    appId: "1:91020574583:web:71a5aa6ff9180dca072b72"
  };

firebase.initializeApp(firebaseConfig)