import React, { useState, CSSProperties, useEffect } from 'react'
import { useSubscribeToMany, useSubscribeToManyOrderBy } from '../../services/FirebaseHooks'
import { postService, labelService } from '../../services/Services'
import { Post } from './Post'
import { TopicPicker } from './TopicPicker'
import { useIsAdmin } from '../../services/Auth'


const useMediaQuery = (query: string) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e: any) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return matches;
};

const containerStyle = (mobile: boolean) => ({
  display: 'flex',
  padding: 32,
  alignItems: mobile ? 'stretch' : 'flex-start',
  flexDirection: mobile ? 'column' : 'row',
})

const postListStyle: CSSProperties = {
  flexGrow: 1
}

export function PostList() {
  const posts = useSubscribeToManyOrderBy(postService, 'date', "desc")
  const isAdmin = useIsAdmin()
  const labels = useSubscribeToMany(labelService)
  const [selectedTopic, setSelectedTopic] = useState<string | null>(null)
  const matches = useMediaQuery("(max-width: 500px)")
  const filteredPosts = selectedTopic ? posts.filter(p => p.label === selectedTopic) : posts

  return (
    <div style={containerStyle(matches) as CSSProperties}>
      <TopicPicker
        selectedTopic={selectedTopic}
        onTopicSelected={topic => setSelectedTopic(topic) }/>
      <div style={postListStyle}>
        {filteredPosts.map(post =>
          <Post
            onDeleteButtonClicked={async () => {
              if (window.confirm('Deseas borrar el anuncio? No podras recuperarlo')) {
                await postService.delete(post.uid)
              }
            }}
            showDeleteButton={isAdmin}
            key={post.uid}
            post={post}
            label={labels.find(l => l.uid === post.label)!!} />
        )}
        {filteredPosts.length === 0 && <div>
          <h1 style={{fontSize: 128, margin: 30}}>
            <span role="img" aria-label="not found icon">🤥</span>
          </h1>
          <h1 style={{color: '#232323'}}>¡No hay nada por aquí!</h1>
        </div>}
      </div>
    </div>
  )
}