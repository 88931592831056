import React, { useState } from 'react'
import { PostEditor } from "./index"
import { postService, labelService } from '../../services/Services'
import { useCreate, useSubscribeToMany } from '../../services/FirebaseHooks'
import { uploadFile } from '../../services/FileUploads'
import { Attachement } from '../../types'
import { firestore } from 'firebase'
import { useHistory } from 'react-router'

export function CreatePost() {
  const [create, result] = useCreate(postService)
  const [buttonEnabled, setButtonEnabled] = useState(true)
  const labels = useSubscribeToMany(labelService)
  const history = useHistory()

  const onSubmit = async (values: any) => {
    setButtonEnabled(false)

    const post = await create({
      title: values.title,
      content: values.content,
      label: values.label,
      date: firestore.Timestamp.now()
    })

    let coverPicturePath = null

    if (values.coverPicture && values.coverPicture[0]) {
      coverPicturePath =  post.uid + '/' + values.coverPicture[0].name
      await uploadFile(values.coverPicture[0], "images_CHANGEME", coverPicturePath)
    }

    let attachments: Attachement[] = []

    if (values.attachments) {
      for (let file of (Array.from(values.attachments) as any)) {
        const filePath = post.uid + '/' + file.name

        await uploadFile(file, "images_CHANGEME", filePath)
        attachments.push({
          displayName: file.name,
          locationPath: filePath,
          type: 'word',
        })
      }
    }

    if (coverPicturePath) {
      post.coverImage = coverPicturePath
    }

    if (attachments.length > 0) {
      post.attachments = attachments
    }

    await postService.update(post.uid, post)
    window.alert('Aununcio creado exitosamente.')
    history.goBack()
  }

  return (
    <div>
      <PostEditor enabled={buttonEnabled} labels={labels} onSubmit={onSubmit} />
    </div>
  )
}