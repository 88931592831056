import {
  POST_COLLECTION_PATH,
  LABEL_COLLECTION_PATH,
  ADMIN_COLLECTION_PATH,
} from './CollectionPaths'
import { FirebaseCRUDCreator } from './FirebaseCrudCreator'
import { Label, Post, Admin } from '../types'

export const labelService = new FirebaseCRUDCreator<Label>(LABEL_COLLECTION_PATH)
export const adminService = new FirebaseCRUDCreator<Admin>(ADMIN_COLLECTION_PATH)
export const postService = new FirebaseCRUDCreator<Post>(POST_COLLECTION_PATH)