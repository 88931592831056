import React, { useEffect } from "react"
import { Route, Switch, Redirect } from 'react-router'
import { useIsAdmin, useAuth, AuthStatus } from "../services/Auth"

import {
  CreatePost,
  LabelEditor,
  Login,
  PostList,
} from "../sections"


export function Router() {
  const isAdmin = useIsAdmin()
  const { status } = useAuth()

  return (
    <Switch>
      {status === AuthStatus.NOT_AUTHENTICATED && <Route exact path="/login" component={Login} />}
      {status !== AuthStatus.AUTHENTICATED && <Redirect to="/login" />}
      {isAdmin && <Route exact path="/nuevo-anuncio" component={CreatePost} />}
      {isAdmin && <Route exact path="/categorias" component={LabelEditor} />}
      {status === AuthStatus.AUTHENTICATED && <Route exact path="/" component={PostList} />}
      <Redirect to="/" />
    </Switch>
  )
}