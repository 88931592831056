import React, { CSSProperties } from 'react'

import { labelService } from '../../services/Services'
import { useSubscribeToMany } from '../../services/FirebaseHooks'

const itemStyle: CSSProperties = {
  padding: 8,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flexStart',
  alignItems: 'center',
}

const colorIndicatorStyle: CSSProperties = {
  borderRadius: 12,
  height: 12,
  width: 12,
  marginRight: 12,
}

type props = {
  onTopicSelected: (id: string|null) => any,
  selectedTopic: string|null,
}

export function TopicPicker({ onTopicSelected, selectedTopic }: props) {
  const labels = useSubscribeToMany(labelService)

  return (
    <div>
      <h2 style={{textAlign: 'start' }}>Categorias</h2>
      {labels.map(label =>
        <div
          key={label.uid}
          style={{
            ...itemStyle,
            backgroundColor: selectedTopic === label.uid ? '#b0bec5' : 'transparent' ,
          }}
          onClick={() => onTopicSelected(label.uid)}>
          <div style={{...colorIndicatorStyle, backgroundColor: label.color }}>&nbsp;</div>
          {label.title}
        </div>
      )}
        <div
          style={{
            ...itemStyle,
            backgroundColor: selectedTopic === null ? '#b0bec5' : 'transparent' ,
          }}
          onClick={() => onTopicSelected(null)}>
            Todos
            </div>
    </div>
  )
}