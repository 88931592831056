import React from 'react';
import './App.css';
import './firebas.config'

import { useIsAdmin, useAuth, AuthStatus } from './services/Auth';
import { AdminToolbar } from  './sections/main/AdminToolbar'
import { Router } from './routing/Router';
import { BrowserRouter } from 'react-router-dom';
import { NormalToolbar } from './sections/main/NormalToolbar';

function App() {
  const isAdmin = useIsAdmin()
  const { status } = useAuth()
  return (
    <div className="App">
      <BrowserRouter>
        {isAdmin && <AdminToolbar />}
        {!isAdmin && status === AuthStatus.AUTHENTICATED && <NormalToolbar />}
        <Router/>
      </BrowserRouter>
    </div>
  );
}

export default App;
