import React, { CSSProperties } from 'react'
import useForm from 'react-hook-form'
import { Label } from '../../types'
import { EnhancedDocSnapshot } from '../../types/EnhancedDocSnapshot'

const cardStyle: CSSProperties = {
  width: '100%',
  maxWidth: 700,
  backgroundColor: '#FFF',
  margin: 'auto',
  marginTop: 64,
  padding: 16,
  textAlign: 'start',
}

const formStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
}

const inputStyle: CSSProperties = {
  marginBottom: 8,
  width: '100%',
  paddingBottom: 24,
  borderBottom: '1px solid #bfbfbf',
}

const textAreaStyle: CSSProperties = {
  height: 300,
  width: '100%',
  resize: 'none',
  boxSizing: 'border-box',
}

type values = {
  title: string,
  content: string,
  label: string,
  coverPicture: FileList,
  attachments: FileList,
}

type props = {
  initialValues?: values,
  labels: EnhancedDocSnapshot<Label>[],
  onSubmit: (values: values) => any,
  enabled: boolean
}

export function PostEditor(props: props) {
  const { register, handleSubmit } = useForm() //TODO ver como cargar defaults

  return (
    <div style={cardStyle}>
      <form style={formStyle} onSubmit={handleSubmit(props.onSubmit as any)}>
            <label style={inputStyle} >
              <p>Titulo</p>
              <input name="title" ref={register({ required: true })} />
            </label>

            <p>Categoria</p>
            <select name="label" ref={register}>
              {props.labels.map(label => <option value={label.uid}>{label.title}</option>)}
            </select>

            <label style={inputStyle} >
              <p>Contenido</p>
              <textarea style={textAreaStyle} name="content" ref={register({ required: true })} />
            </label>

            <label style={inputStyle} >
              <p>Foto de Portada</p>
              <input name="coverPicture" accept=".jpg,.jpeg,.png,.webp" type="file" ref={register} />
            </label>

            <label style={inputStyle}>
              <p>Archivos adjuntos</p>
              <input
                name="attachments"
                accept=".jpg,.jpeg,.png,.webp,.pdf,.docx,.doc,.txt,.xlsx,.xlsx"
                type="file"
                multiple
                ref={register} />
            </label>

            <button disabled={!props.enabled} type="submit" style={{marginTop: 20 }}>
              guardar
            </button>
      </form>
    </div>
  )
}
