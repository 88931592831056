import React, { CSSProperties } from "react"
import useForm from 'react-hook-form'
import { login } from "../../services/Auth"

const cardStyle: CSSProperties = {
  width: '100%',
  maxWidth: '400px',
  backgroundColor: '#FFF',
  margin: 'auto',
  marginTop: 100,
  padding: 32,
  borderRadius: 8,
  boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.1)',
}

const formStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
}

const inputStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 8,
}

type formValues = {
  email: string,
  password: string,
  persist: boolean,
}

function formatUserEmail(email: string) {
  if (email.includes('@')) {
    return email
  }

  return `${email}@ceies.com`
}

export function Login() {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: localStorage.getItem('email'),
      password: localStorage.getItem('password'),
      persist: Boolean(localStorage.getItem('persist'))
    },
  }) //TODO ver como cargar defaults

  const onLogin = ({ email, password, persist }: formValues ) => {
    console.log(persist)
    if (persist) {
      localStorage.setItem('email', email)
      localStorage.setItem('password', password)
      localStorage.setItem('persist', persist.toString())
    } else {
      localStorage.removeItem('email')
      localStorage.removeItem('password')
      localStorage.removeItem('persist')
    }
  
    Promise.resolve(login(formatUserEmail(email), password)).catch(err => {
      window.alert("Hay un error en el usuario o contraseña")
    })
  }

  return (
    <div style={cardStyle}>
      <form style={formStyle} onSubmit={handleSubmit(onLogin as any)} autoComplete="off">
        <label style={inputStyle} >
          Usuario
          <input name="email" ref={register({ required: true })} />
        </label>
        <label style={inputStyle} >
          Contraseña
          <input name="password" type="password" ref={register({ required: true })} />
        </label>
        <label>
          Recordar Contraseña
            <input type="checkbox" id="persist" name="persist" value="persist" ref={register}/>
        </label>
        <button type="submit">
          Iniciar sesión
        </button>
      </form>
    </div>
  )
}