import React, { CSSProperties, useState, useEffect, Fragment } from 'react'
import {Label} from './Label'
import { Post as PostType, Label as LabelType } from '../../types'
import { getStoragePath } from '../../services/FileUploads'
import { DownloadButton } from './DownloadButton'
import { timestamp2PrettyDate } from '../../utils/utils'

const cardStyle: CSSProperties = {
    backgroundColor: "#fff",
    color:"#000",
    maxWidth: 650,
    padding:12,
    borderRadius:4,
    boxShadow:"0px 4px 6px 0px #ececec",
    margin: 'auto',
    marginBottom: 16,
}

const headerContainerStyle: CSSProperties = {
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    marginBottom: 16,
    flexWrap: 'wrap',
}

const headerStyle: CSSProperties = {
    margin:0,
    fontFamily: "'Roboto Slab', serif",
    fontWeight: 400,
}

const contentStyle: CSSProperties = {
    textAlign: "start",
    fontSize: 16
}

const attachmentTitleStyle: CSSProperties = {
    textAlign: 'start',
    marginTop: 48,
    paddingTop: 8,
    borderTop: '1px solid #efefef',
    fontSize: 20,
    fontWeight: 400,
    color: '#383838',
}

const deleteButtonStyle: CSSProperties = {
    width: '100%',
    backgroundColor: 'red',
    borderColor: 'red',
}

type props = {
    post: PostType,
    label: LabelType,
    showDeleteButton: Boolean,
    onDeleteButtonClicked: () => void
}

export function Post({ post, label, showDeleteButton, onDeleteButtonClicked }: props) {
    const [coverImageUrl, setCoverImageUrl] = useState<string>()

    useEffect(() => {
        if (post.coverImage) {
            getStoragePath(post.coverImage).then(path => {
                setCoverImageUrl(path)
            })
        }
    }, [post.coverImage])

    return (
        <div style={cardStyle}>
            <div style={headerContainerStyle}>
                <h1 style={headerStyle}>{post.title}</h1>
                {label && <Label
                    title={label.title}
                    color={label.color} />}
            </div>
            {coverImageUrl && <img
                onClick={() => window.open(coverImageUrl, '_')}
                style={{ width: '100%' }}
                alt="imagen"
                src={coverImageUrl}>
                </img>}
            {post.date && <div style={{
                textAlign:"start",
                fontSize:16,
                color:"#525252"}}>
                {timestamp2PrettyDate(post.date)}
            </div>}
            <p style={contentStyle}>{post.content}</p>
            {post.attachments &&
                <Fragment>
                    <h2 style={attachmentTitleStyle}>Archivos adjuntos</h2>
                    {post.attachments.map(a =>
                        <DownloadButton
                            key={a.locationPath}
                            title={a.displayName}
                            href={a.locationPath}
                            type={a.type} />
                    )}
                </Fragment>}
                {showDeleteButton && <button onClick={onDeleteButtonClicked} style={deleteButtonStyle}>Borrar Anuncio</button>}
        </div>
    )
}