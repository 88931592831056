import React from 'react'

export function Label({ title, color }) {
    return (
        <div style={{
                backgroundColor: color,
                paddingTop:4,
                paddingLeft:12,
                paddingRight:12,
                paddingBottom:4,
                borderRadius:4,
                margin:0
            }}>
            <span>
            {title}
            </span>
        </div>
    )
}