import React, { CSSProperties } from 'react'
import { Link } from 'react-router-dom'
import { signout } from '../../services/Auth'

const toolbarStyle: CSSProperties = {
  height: 64,
  color: '#FFF',
  backgroundColor: '#283593',
}

const innerContainerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-start'
}

const itemStyle: CSSProperties = {
  display: 'inline-block',
  textDecoration: 'none',
  color: "#FFF",
  margin: 20,
  fontSize: 18
}

const logoutButtonStyle: CSSProperties = {
  ...itemStyle,
  marginLeft: 'auto',
  cursor: 'pointer',
}
export function AdminToolbar() {
  // const location = useLocation() TODO add active styles
  return (
    <div style={toolbarStyle}>
      <div style={innerContainerStyle}>
        <Link
          style={itemStyle}
          to="/categorias">
            Editar Categorias
        </Link>
        <Link
          style={itemStyle}
          to="/nuevo-anuncio">
            Crear anuncio
        </Link>
        <Link
          style={itemStyle}
          to="/">
            Ver anuncios
        </Link>
        <div style={logoutButtonStyle} onClick={signout}>
          Cerrar Sesión
        </div>
      </div>
    </div>
  )
}