import React, { CSSProperties } from 'react'
import { FileType } from "../../types"
import { openStoragePath } from '../../services/FileUploads'

type props = {
  title: string,
  type: FileType,
  href: string,
}

const ancherStyle: CSSProperties = {
  color: '#03A9F4',
  textDecoration: 'underline',
  display: 'block',
  paddingBottom: 8,
  textAlign: 'start',
  cursor: 'pointer'
}


export function DownloadButton({ title, type, href }: props) {
  return (
    <a style={ancherStyle} onClick={(ev) => {
      ev.preventDefault()
      openStoragePath(href)
    }}>
      {title}
    </a>
  )
}