import { storage } from 'firebase'

const storageInstance = storage()
const storageRef = storageInstance.ref()


export function getStoragePath(path: string) {
  return storageRef
    .child(path)
    .getDownloadURL()
}

export function openStoragePath(path: string) {
  return storageRef
    .child(path)
    .getDownloadURL()
    .then(url =>  window.open(url, '_'))
}

export function getFileFromRef(ref: React.MutableRefObject<any>): File|null{
  return ref.current &&
    (ref.current as unknown as HTMLInputElement).files &&
    (ref.current as unknown as HTMLInputElement).files!![0]
}

export function uploadFile(file: File, path: string, uuid: string) {
  const fileRef = storageRef.child(uuid)

  return fileRef.put(file, {
    contentType: file.type,
    contentDisposition : "attachment; filename=" + file.name,
  }).then(console.log)
}