import React, { useState, CSSProperties } from 'react'
import { CirclePicker } from 'react-color'
import '@simonwep/pickr/dist/themes/nano.min.css'
import { useCreate, useSubscribeToMany } from '../../services/FirebaseHooks'
import { labelService, postService } from '../../services/Services'

const pickerClass = ".color-picker"

const containerStyles: CSSProperties = {
  width: 400,
  margin: 'auto',
  marginTop: 52,

}

const previewStyle: CSSProperties = {
  height: 40,
  width: 40,
  border: '1px solid #e0e0e0',
  borderRadius: 2,
}


const itemStyle: CSSProperties = {
  padding: 8,
  display: 'flex',
  justifyContent: 'flexStart',
  alignItems: 'center',
}

const colorIndicatorStyle: CSSProperties = {
  borderRadius: 12,
  height: 12,
  width: 12,
  marginRight: 12,
}

const deleteButtonStyle: CSSProperties = {
  marginLeft: 'auto',
  backgroundColor: 'red',
  borderColor: 'red',
  cursor: 'pointer',
}

const inputStyle: CSSProperties = {
  alignItems: 'center',
  justifyContent: 'space-between',
}

export function LabelEditor() {
  const [title, setTitle] = useState<string>("")
  const [color, setColor] = useState<string>("#FFF")
  const [showingPicker, setShowingPicker] = useState<boolean>(false)
  const [create, created] = useCreate(labelService)
  const labels = useSubscribeToMany(labelService)

  const submit = async () => {
    if (labels.find(l => l.title.toLocaleLowerCase() === title.toLocaleLowerCase())) {
      alert(`Ya existe una categoria con el nombre ${title}.`)
    } else {
      await create({ color, title })  
    }  
  }
  
  const deleteLabel = async (uid: string) => {
    const postsWithLabel = await postService.getWhere('label', '==', uid)
    const count = postsWithLabel.length

    if (count > 0) {
      alert(`Hay ${count} anuncio(s) con esta categoria. Para que pueda ser borrada, no debe haber anuncios con esta categoria. Ve a la sección de anuncios, borrarlos e intenta de nuevo.`)
    } else {
      if (window.confirm('Deseas borrar la categoria?')) {
        await labelService.delete(uid)
      }
    }
  }

  return (
    <div style={containerStyles}>
      <div>
        <h2 style={{textAlign: 'start' }}>Categorias</h2>
        {labels.map(label =>
          <div
            key={label.uid}
            style={itemStyle}>
            <div style={{...colorIndicatorStyle, backgroundColor: label.color }}>&nbsp;</div>
            {label.title}
            <button style={deleteButtonStyle} onClick={() => deleteLabel(label.uid)}>Eliminar</button>
          </div>
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column'}}>
        <h2 style={{textAlign: 'start' }}>Categorias</h2>
        <label style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}>
          Nombre:
            <input style={inputStyle} type="text" onChange={ev => setTitle(ev.target.value)} value={title}></input>
        </label>
        <label style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}>
          Color:
          <div
            onClick={() => setShowingPicker(true)}
            style={{ backgroundColor: color, ...previewStyle }}>
            &nbsp;
          </div>
        </label>
        {showingPicker &&
          <CirclePicker
            onChange={color => {
              setColor(color.hex)
              setShowingPicker(false)
            }}
          />}
        <button onClick={submit}>Guardar</button>
      </div>
    </div>
  )
}