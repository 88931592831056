import { useState, useEffect } from "react"
import { Unsubscribe, auth } from "firebase"
import { adminService } from "./Services"

const authInstance = auth()
authInstance.setPersistence(auth.Auth.Persistence.LOCAL)

export function signout() {
  return authInstance.signOut()
}

export class UnknownError extends Error {}

export class WrongCredentialsError extends Error {
  message = "Wrong email or password"
}

export class EmailAlreadyInUse extends Error {
  message = "Email is already in use"
}

export class WeakPasswordError extends Error {
  message = "The password needs to have 6 characters and at least 1 number"
}

/**
 * @returns the unique id of the logged in user.
 * @throws `WrongCredentialsError` if a wrong email and password combination is used
 */
export async function login (email: string, password: string) {
  try {
    const credentials = await authInstance.signInWithEmailAndPassword(email, password)

    if (!credentials.user) {
      throw new UnknownError("Signup error")
    }

    return credentials.user.uid

  } catch(error) {
    if (error.code === 'auth/wrong-password' || error.code === 'auth/invalid-email') {
      throw new WrongCredentialsError()
    } else {
      throw new UnknownError()
    }
  }
}

export enum AuthStatus {
  AUTHENTICATED,
  NOT_AUTHENTICATED,
  UNDEFINED
}

/**
 * @param onStatusChanged called each time a user logs in, signs up or logs out.
 * @returns a function to stop listening.
 */
export function observeAuthStatus(
  onStatusChanged: (authStatus: AuthStatus, uid: string | undefined) => any
): Unsubscribe {
  return authInstance.onAuthStateChanged((user) => {
    if (user) {
      onStatusChanged(AuthStatus.AUTHENTICATED, user.uid)
    } else {
      onStatusChanged(AuthStatus.NOT_AUTHENTICATED, undefined)
    }
  })
}

export function getCurrentUserUid() {
  return authInstance.currentUser && authInstance.currentUser.uid
}


export type AuthState = {
  status: AuthStatus
  uid?: string
}

export function useAuth() {
  const [authStatus, setAuthStatus] = useState<AuthState>({ status: AuthStatus.NOT_AUTHENTICATED})

  useEffect(() => {
    console.log('subscribing')
    const unsubscribe = observeAuthStatus((status, uid) => setAuthStatus({ status, uid }))

    return () => {
      unsubscribe()
      console.log('unsubscribing')
    }
  }, [])

  return authStatus
}

export function useIsAdmin() {
  const authStatus = useAuth()
  const [isAdmin, setIsAdmin] = useState<boolean>(false)

  if (authStatus.status === AuthStatus.AUTHENTICATED) {
    adminService.exists(authStatus.uid!!).then(exists => {
      setIsAdmin(exists)
    })
  } else {
    if (isAdmin !== false) {
      setIsAdmin(false)
    }
  }

  return isAdmin
}