import React, { CSSProperties } from 'react'

const toolbarStyle: CSSProperties = {
  height: 64,
  color: '#FFF',
  backgroundColor: '#26a69a',
}

const innerContainerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-start'
}

const headerStyle: CSSProperties = {
  display: 'inline-block',
  textDecoration: 'none',
  color: "#FFF",
  margin: 20,
  fontSize: 18
}

export function NormalToolbar() {
  return (
    <div style={toolbarStyle}>
      <div style={innerContainerStyle}>
          <h1 style={headerStyle}>Anuncios</h1>
          <a href="https://secundariaceies.blogspot.com"><h1 style={headerStyle}>Blog de Secundaria</h1></a>
      </div>
    </div>
  )
}