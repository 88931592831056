import { firestore } from "firebase";
import { useEffect, useState } from "react";
import { FirebaseCRUDCreator } from "./FirebaseCrudCreator";
import { EnhancedDocSnapshot } from "../types/EnhancedDocSnapshot";
import { toEDoc } from "./toEDoc";

/**
 * Funciones para cargar datos directo en un componente.
 * TODO: Agregar ejemplos y buena documentacion.
 */
export function useGetMany<T>(service: FirebaseCRUDCreator<T>) {
  const [data, setData] = useState<EnhancedDocSnapshot<T>[]>([])

  useEffect(() => {
    service.getMany().then(docs => setData(docs))
  }, [])

  return data
}

export function useSubscribeToMany<T>(service: FirebaseCRUDCreator<T>) {
  const [data, setData] = useState<EnhancedDocSnapshot<T>[]>([])

  useEffect(() => {
    return service.subscribeToMany(docs => {
      setData(docs)
    })
  }, [])

  return data
}

export function useSubscribeToManyOrderBy<T>(
  service: FirebaseCRUDCreator<T>,
  orderByField: string,
  direction: firestore.OrderByDirection
) {
  const [data, setData] = useState<EnhancedDocSnapshot<T>[]>([])

  useEffect(() => {
    return service.subscribeToManyOrderBy(orderByField, direction, docs => {
      setData(docs)
    }, )
  }, [])

  return data
}

export function useSubscribeWhere<T>(
  field: string,
  filter: firestore.WhereFilterOp,
  value: string,
  service: FirebaseCRUDCreator<T>
) {
  const [data, setData] = useState<EnhancedDocSnapshot<T>[]>([])

  useEffect(() => {
    return service.subscribeWhere(
      field,
      filter,
      value,
      docs => {
        setData(docs)
      })
  }, [])

  return data
}

export function useSubscribeWhereSorted<T>(
  field: string,
  filter: firestore.WhereFilterOp,
  value: string,
  orderByField: string,
  direction: firestore.OrderByDirection,
  service: FirebaseCRUDCreator<T>
) {
  const [data, setData] = useState<EnhancedDocSnapshot<T>[]>([])

  useEffect(() => {
    return service.subscribeWhereOrderedBy(
      field,
      filter,
      value,
      orderByField,
      direction,
      docs => {
        setData(docs)
      })
  }, [])

  return data
}


/**
 * TODO: ver si cambiar el api para que esto regrese un 'hook' y usar ese
 * tipo
 *
 * useUser = useGetOne<User>(Services.UserService)
 *
 * useUser(uid)
 */

export enum Status {
  NONE,
  PENDING,
  SUCCESS,
  FAILURE
}

export function useGetOne<T>(uid: string, service: FirebaseCRUDCreator<T>): [T|null, Status] {
  const [data, setData] = useState<EnhancedDocSnapshot<T>|null>(null)
  const [status, setStatus] = useState<Status>(Status.PENDING)

  useEffect(() => {
    setStatus(Status.PENDING)

    service.getOne(uid).then(doc => {
      setData(doc)
      setStatus(Status.SUCCESS)
    }).catch(() => {
      setStatus(Status.FAILURE)
    });
  }, [uid])

  return [data, status] as any
}


export function useGetNewUUID(collection: firestore.CollectionReference): [string|null, () => Promise<void>] {
  const [createdId, setCreatedId] = useState<string|null>(null)
  //Create empty document and get ID
  const create = () => collection.add({}).then(ref => {
    setCreatedId(ref.id)
  })

  return [createdId, create] as any
}

export function useCreate<T>(service: FirebaseCRUDCreator<T>): [(data: Partial<T>) => Promise<EnhancedDocSnapshot<T>>, EnhancedDocSnapshot<T>|null] {
  const [createdData, setCreatedData] = useState<EnhancedDocSnapshot<T>|null>(null)

  const create = (data: Partial<T>) => service.addPartial(data).then(async ref => {
    const ss = await ref.get()
    const edoc = toEDoc<T>(ss);

    setCreatedData(edoc)
    return edoc
  })

  return [create, createdData]
}

export function useUpdateWithStatus<T>(
  service: FirebaseCRUDCreator<T>
): [Status, (uid: string, values: Partial<T>) => void, () => void] {
  const [status, setStatus] = useState<Status>(Status.NONE);

  function action(uid: string, values: Partial<T>) {
      setStatus(Status.PENDING)

      service.update(uid, values).then(() => {
          setStatus(Status.SUCCESS)
      }).catch(() => {
          setStatus(Status.FAILURE)
      })
  }

  function reset() {
    setStatus(Status.NONE)
  }

  return [status, action, reset]
}